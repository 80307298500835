import { Component, OnInit, ApplicationRef, NgZone } from "@angular/core";
import { DashboardService } from "../services/dashboard.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ModalComponent } from "../shared/modal/modal.component";
import { first, switchMap } from "rxjs/operators";
import { interval } from "rxjs";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public load = false;
  public dataTable = [];
  public date = new Date();
  public minDate = new Date();
  public maxDate: Date;
  public delayedDays: number;
  public dueDate: Date;
  public daysLeft: number;
  public installments;
  public olderDueDate: Date;
  public qnt = 0;
  public total = 0;
  public user;
  public oldUser = null;
  public userToShow;
  public loggedDocument;
  public innerWidth: any;

  public hasDelayed30More = false;
  public alreadyShownParcels = false;

  constructor(
    private _dashboardService: DashboardService,
    public _matDialog: MatDialog,
    appRef: ApplicationRef,
    zone: NgZone
  ) {
    appRef.isStable
      .pipe(
        first((stable) => stable),
        switchMap(() => interval(1000))
      )
      .subscribe(() => {
        zone.run(() => {
          if (
            localStorage.getItem("imobUser") != undefined &&
            localStorage.getItem("imobUser") != null
          ) {
            this.user =
              (!!localStorage.getItem("imobUser") &&
                JSON.parse(localStorage.getItem("imobUser")).data) ||
              null;
          }
          // Atribui o CPF ou CNPJ que pesquisou o contrato sem as pontuações na variável para posterior uso
          if (
            localStorage.getItem("loggedDocument") != undefined &&
            localStorage.getItem("loggedDocument") != null
          ) {
            this.loggedDocument = localStorage
              .getItem("loggedDocument")
              .replace(/[.-\s\/]/g, "");
          }
        });
      });
  }

  selectedItems(items: Object) {
    console.log(items);
  }

  updateDate(date) {
    this.getInstallments(date);
  }

  //Função para adicionar dias
  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // Função para obter último dia do mês
  // getLastDayOfMonth() {
  //   var d = new Date()
  //   var month = d.getMonth()
  //   var year = d.getFullYear()
  //   var lastDay = new Date(year, month + 1, 0).getDate()
  //   this.maxDate = new Date(year, month, lastDay);
  // }

  getInstallments(changedDate?) {
    this.total = 0;
    this.qnt = 0;
    this._dashboardService
      .getInstallments(this.user, changedDate ? changedDate : null)
      .then(
        (data) => {
          if (data.installments.length > 0) {
            this.dataTable = data.installments;
            this.installments = data.installments;
            this.olderDueDate = this.installments.reduce(
              (min: Date, installment) =>
                installment.dueDate < min ? installment.dueDate : min,
              this.installments[0].dueDate
            );

            this.dataTable.map((val) => {
              this.hasDelayed30More = false;
              if (val.delayedDays > 0) {
                if (this.qnt < this.dataTable.length) {
                  this.qnt += 1;
                  this.total = this.total + val.totalPayable * 100;
                }
              }
              if (val.delayedDays > 30) {
                this.hasDelayed30More = true;
              }
              if (val.delayedDays <= 30) {
                this.maxDate = this.addDays(this.olderDueDate, 31);
              }
              return val;
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  ngOnInit() {
    this.load = true;
    this.innerWidth = window.innerWidth;
    this.getLocalStorage();
    this.getInstallments();
    // Para usar os dados do CPF que pesquisou o contrato e não apenas o principal
    this.userToShow = JSON.parse(
      JSON.stringify(
        this.user.borrowers.find((borrower) =>
          borrower.personType == "F"
            ? Number(borrower.document) == Number(this.loggedDocument)
            : borrower.document == this.loggedDocument
        )
      )
    );
  }

  ngDoCheck() {
    this.getLocalStorage();
  }

  getLocalStorage() {
    if (
      localStorage.getItem("imobUser") == undefined ||
      localStorage.getItem("imobUser") == null
    ) {
      this.load = true;
      this.user = null;
      this.userToShow = null;
      this.dataTable = [];
      this.installments = null;
      this.hasDelayed30More = false;
    } else {
      if (JSON.parse(localStorage.getItem("imobUser")).data) {
        this.user = JSON.parse(localStorage.getItem("imobUser")).data;
      }
    }

    if (
      this.user != undefined &&
      this.user != null &&
      localStorage.getItem("loggedDocument") != undefined &&
      localStorage.getItem("loggedDocument") != null &&
      this.userToShow == null
    ) {
      this.loggedDocument = localStorage
        .getItem("loggedDocument")
        .replace(/[.-\s\/]/g, "");
      // Para usar os dados do CPF que pesquisou o contrato e não apenas o principal
      this.userToShow = JSON.parse(
        JSON.stringify(
          this.user.borrowers.find((borrower) =>
            borrower.personType == "F"
              ? Number(borrower.document) == Number(this.loggedDocument)
              : borrower.document == this.loggedDocument
          )
        )
      );
      this.getInstallments();
      this.load = false;
    }
  }

  openUnavailableParcelsModal() {
    if (!this.alreadyShownParcels && this.hasDelayed30More) {
      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      // dialogConfig.disableClose = true;
      dialogConfig.id = "modal-unavailable-parcels";
      dialogConfig.minHeight = "470px";
      if (this.innerWidth < 550) {
        dialogConfig.width = "95%";
      } else {
        dialogConfig.minWidth = "450px";
      }
      dialogConfig.maxHeight = "670px";
      dialogConfig.maxWidth = "550px";
      dialogConfig.data = {
        name: "unavailableParcels",
        title: "Parcelas indisponíveis para pagamento",
        description: `<p>Seu contrato possui parcelas com mais de 30 dias de atraso e o pagamento através do portal está indisponível.</p>
          <p>Entre em contato com a CEBRACO
          <a href="mailto:cobrancaimobiliario@cebracopr.com.br"
            >cobrancaimobiliario@cebracopr.com.br</a
          ><br />
          TEL: (41) 3017-7855 ou 0800-600-7017 <br />
          <a target="_blank" href="https://wa.me/5541988353077">WhatsApp: (41) 98835-3077.</a></p>`,
        // actionButtonText: "Entendi",
        isButtonEnabled: true,
        cancelButtonText: "Entendi",
        imageSrc: "../../../assets/icons/rounded-exclamation-icon.svg",
      };
      const modalDialog = this._matDialog.open(ModalComponent, dialogConfig);
    }
    this.alreadyShownParcels = true;
  }
}
