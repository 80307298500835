import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ModalActionsService {
  constructor() {}

  modalAction(modalData: any) {
    switch (modalData.name) {
      case "exampleModal":
        this.exampleModal(modalData);
        break;

      default:
        break;
    }
  }

  private exampleModal(modalData: any) {
    console.log(modalData + " Modal de exemplo");
  }
}
