import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private _router: Router,
    private _authService: AuthService,
  ) {
    // redirect to home if already logged in
    if (this._authService.authServ.currentUserValue()) {
      this._router.navigate(["/"]);
    }
  }

  ngOnInit() {}
}
