import { AbstractControl } from '../../node_modules/@angular/forms';

export class Validations {
  static ValidateCpfCnpj(control: AbstractControl) {
    const cpfCnpj = control.value;

    if (cpfCnpj.length < 15) {
      const formattedValue = cpfCnpj.replace(".", "").replace(".", "").replace("-", "");

      let sum: number = 0;
      let rest: number;
      let valid: boolean;

      const regex = new RegExp('[0-9]{11}');

      if (
        formattedValue == '00000000000' ||
        formattedValue == '11111111111' ||
        formattedValue == '22222222222' ||
        formattedValue == '33333333333' ||
        formattedValue == '44444444444' ||
        formattedValue == '55555555555' ||
        formattedValue == '66666666666' ||
        formattedValue == '77777777777' ||
        formattedValue == '88888888888' ||
        formattedValue == '99999999999' ||
        !regex.test(formattedValue)
      )
        valid = false;
      else {
        for (let i = 1; i <= 9; i++)
          sum = sum + parseInt(formattedValue.substring(i - 1, i)) * (11 - i);
        rest = (sum * 10) % 11;

        if (rest == 10 || rest == 11) rest = 0;
        if (rest != parseInt(formattedValue.substring(9, 10))) valid = false;

        sum = 0;
        for (let i = 1; i <= 10; i++)
          sum = sum + parseInt(formattedValue.substring(i - 1, i)) * (12 - i);
        rest = (sum * 10) % 11;

        if (rest == 10 || rest == 11) rest = 0;
        if (rest != parseInt(formattedValue.substring(10, 11))) valid = false;
        valid = true;
      }

      if (valid) return null;

      return { cpfCnpjInvalid: true };
    }
    else {
      const formattedValue = cpfCnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", "");

      let size;
      let numbers;
      let digits;
      let sum;
      let pos;
      let result;

      if (formattedValue.length != 14)
        return { cpfCnpjInvalid: true };

      // Elimina CNPJs invalidos conhecidos
      if (formattedValue == "00000000000000" ||
        formattedValue == "11111111111111" ||
        formattedValue == "22222222222222" ||
        formattedValue == "33333333333333" ||
        formattedValue == "44444444444444" ||
        formattedValue == "55555555555555" ||
        formattedValue == "66666666666666" ||
        formattedValue == "77777777777777" ||
        formattedValue == "88888888888888" ||
        formattedValue == "99999999999999")
        return { cpfCnpjInvalid: true };

      // Valida DVs
      size = formattedValue.length - 2
      numbers = formattedValue.substring(0, size);
      digits = formattedValue.substring(size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result != digits.charAt(0))
        return { cpfCnpjInvalid: true };

      size = size + 1;
      numbers = formattedValue.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result != digits.charAt(1))
        return { cpfCnpjInvalid: true };

      return null;
    }
  }

  static ValidateContract(control: AbstractControl) {
    const contract = control.value;

    const regex = new RegExp('^[0-9]{4,6}$');

    if (regex.test(contract)) {
      return null
    } else {
      return { contractInvalid: true };
    }
  }
}
