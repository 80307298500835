import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-contract-data",
  templateUrl: "./contract-data.component.html",
  styleUrls: [
    "../../dashboard.component.scss",
    "./contract-data.component.scss"
  ]
})
export class ContractDataComponent implements OnInit {
  @Input() user;
  @Input() userToShow;

  constructor() {}

  public cpfCnpj: string;

  ngOnInit(){

  }

  ngDoCheck() {
    if (this.user){
      if (this.user.borrowers[0].personType == "F") {
        this.cpfCnpj = this.user.borrowers[0].document;
      } else {
        this.cpfCnpj = this.user.borrowers[0].document;
      }
    }
  }
}
