import { Component, ApplicationRef, NgZone, DoCheck } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { first, switchMap } from "rxjs/operators";
import { interval } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements DoCheck {
  public user;
  public userToShow;
  public loggedDocument;
  public innerWidth: any;

  constructor(
    public router: Router,
    private _authService: AuthService,
    appRef: ApplicationRef,
    zone: NgZone,
    public _matDialog: MatDialog
  ) {
    appRef.isStable
      .pipe(
        first((stable) => stable),
        switchMap(() => interval(1000))
      )
      .subscribe(() =>
        zone.run(
          () =>
            (this.user =
              (!!localStorage.getItem("imobUser") &&
                JSON.parse(localStorage.getItem("imobUser")).data) ||
              null)
        )
      );
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  public logout = () => {
    //Remove o documento que pesquisou o contrato do localStorage
    localStorage.removeItem("loggedDocument");
    this._authService.logout();
  };

  newConsultationModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-unavailable-login";
    dialogConfig.minHeight = "470px";
    if (this.innerWidth < 550) {
      dialogConfig.width = "95%";
    } else {
      dialogConfig.minWidth = "450px";
    }
    dialogConfig.maxHeight = "670px";
    dialogConfig.maxWidth = "550px";
    dialogConfig.data = {
      name: "newConsultation",
      isLoginModal: true,
    };
    this._matDialog.open(ModalComponent, dialogConfig);
  }

  ngDoCheck() {
    this.user =
      (!!localStorage.getItem("imobUser") &&
        JSON.parse(localStorage.getItem("imobUser")).data) ||
      null;
    if (
      this.user != undefined &&
      this.user != null &&
      localStorage.getItem("loggedDocument") != undefined &&
      localStorage.getItem("loggedDocument") != null
    ) {
      this.loggedDocument = localStorage
        .getItem("loggedDocument")
        .replace(/[.-\s\/]/g, "");
      // Para usar os dados do CPF que pesquisou o contrato e não apenas o principal
      this.userToShow = JSON.parse(
        JSON.stringify(
          this.user.borrowers.find((borrower) =>
            borrower.personType == "F"
              ? Number(borrower.document) == Number(this.loggedDocument)
              : borrower.document == this.loggedDocument
          )
        )
      );
    } else {
      this.userToShow = "";
    }
  }
}
