import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";

import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";

import { NgxMaskModule } from "ngx-mask";

import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";

import {
  MatExpansionModule,
  MatButtonModule,
  MatInputModule,
  MatSliderModule,
  MatTableModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatIconModule,
  MAT_DATE_LOCALE
} from "@angular/material";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { ModalComponent } from "./shared/modal/modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { TableComponent } from "./shared/table/table.component";
import { ContractDataComponent } from "./dashboard/partials/contract-data/contract-data.component";
import { FinancialSummaryComponent } from "./dashboard/partials/financial-summary/financial-summary.component";
import { CallCenterComponent } from "./dashboard/partials/call-center/call-center.component";
import { MyParcelsComponent } from "./dashboard/partials/my-parcels/my-parcels.component";
import { InformationCardComponent } from "./dashboard/partials/information-card/information-card.component";
import { LoginFormComponent } from './shared/login-form/login-form.component';

registerLocaleData(localePt, "pt");

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    TableComponent,
    DashboardComponent,
    ContractDataComponent,
    FinancialSummaryComponent,
    CallCenterComponent,
    MyParcelsComponent,
    InformationCardComponent,
    ModalComponent,
    LoginFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatTableModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    MatDialogModule
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt" }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent]
})
export class AppModule {}
