import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { User } from "../models/user.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthService {
  public currentUser: Observable<User>;

  private _token = {};

  constructor(private http: HttpClient, private _router: Router) { }

  public authServ = {
    currentUserValue: () => {
      return JSON.parse(localStorage.getItem("imobUser"));
    },
  };

  public login(contract, cpfcnpj): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.URL}/realestate/v1/contracts/${contract}?uuid=${cpfcnpj}`, { observe: 'response' }
        )
        .subscribe(
          (data) => {
            if (data["error"]) {
              reject(data["error"]);
            }

            localStorage.setItem(
              "imobUser",
              JSON.stringify({ ...this._token, data: data.body })
            );
            localStorage.setItem("loggedDocument", cpfcnpj);
            resolve(data);
          },
          (error) => {
            localStorage.removeItem("imobUser");
            reject(error);
          }
        );
    });
  }

  public logout() {
    // remove user and document from local storage and set current user to null
    localStorage.removeItem("imobUser");
    localStorage.removeItem("loggedDocument");
    this._router.navigate(["/login"]);
  }

  public reOpenDashboard() {
    // remove user and document from local storage and set current user to null
    localStorage.removeItem("imobUser");
    localStorage.removeItem("loggedDocument");
  }
}
