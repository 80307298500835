import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { DashboardService } from "../../../services/dashboard.service";
import { TableComponent } from "src/app/shared/table/table.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ModalComponent } from "src/app/shared/modal/modal.component";

@Component({
  selector: "app-my-parcels",
  templateUrl: "./my-parcels.component.html",
  styleUrls: ["./my-parcels.component.scss"],
})
export class MyParcelsComponent implements OnInit, OnChanges {
  @Input() dataTable = [];
  @Input() minDate = new Date();
  @Input() maxDate;
  @Input() date = new Date();
  @Input() qnt: number;
  @Input() total: number;
  @Input() user;
  @Input() hasDelayed30More = Boolean;
  @Output() selectedItemsEvent = new EventEmitter<Object>(true);
  @ViewChild(TableComponent, { static: true }) table: TableComponent;
  @Output("updateDate") updateDate: EventEmitter<any> = new EventEmitter();

  public selectedParcels = { parcels: [] };
  public isLoading = false;
  public innerWidth: any;
  public invoiceDate: string;

  constructor(
    private _dashboardService: DashboardService,
    public _matDialog: MatDialog
  ) {}

  changeDate(date) {
    // Converte o objeto de DATA para o formato yyyy-mm-dd
    const stringified = JSON.stringify(date.value);
    this.invoiceDate = stringified.substring(1, 11);
    this.updateDate.emit(this.invoiceDate);
  }

  saveToDisk(fileURL, fileName) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    save.download = fileName || "unknown";

    var evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    save.dispatchEvent(evt);

    (window.URL || (window as any).webkitURL).revokeObjectURL(save.href);
  }

  generateInvoice() {
    window.URL = window.URL || (window as any).webkitURL;
    // Verifica se o navegador é Safari
    // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.isLoading = true;
    this.selectedParcels.parcels = this.table
      .getSelectedParcels()
      .map((parcel) => parcel.parcel);
    this._dashboardService
      .generateInvoice(
        this.user,
        this.selectedParcels,
        this.invoiceDate ? this.invoiceDate : null
      )
      .subscribe(
        (data) => {
          var fileURL = window.URL.createObjectURL(data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //Para funcionar no IE / Edge, pois bloqueiam blob
            window.navigator.msSaveOrOpenBlob(data, "Boleto.pdf");
          } else {
            // window.open(fileURL);
            this.saveToDisk(fileURL, "Boleto.pdf");
          }
          this.openInvoicePopupModal();
          this.isLoading = false;
        },
        (err) => {
          console.error(err);
          this.openErrorModal();
          this.isLoading = false;
        }
      );
  }

  openErrorModal() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    // dialogConfig.disableClose = true;
    dialogConfig.id = "modal-error";
    dialogConfig.minHeight = "470px";
    if (this.innerWidth < 550) {
      dialogConfig.width = "95%";
    } else {
      dialogConfig.minWidth = "450px";
    }
    dialogConfig.maxHeight = "670px";
    dialogConfig.maxWidth = "550px";
    dialogConfig.data = {
      name: "error",
      title: "Oops! Parece que alguma coisa não deu certo.",
      description: `<p>Aconteceu alguma coisa durante a emissão do boleto e por isso ele não foi gerado.</p>
      <p>Selecione novamente as parcelas que deseja pagar e clique em gerar boleto.</p>`,
      // actionButtonText: "Entendi",
      isButtonEnabled: true,
      cancelButtonText: "Entendi",
      imageSrc: "../../../assets/icons/diamond-exclamation-icon.svg",
    };
    const modalDialog = this._matDialog.open(ModalComponent, dialogConfig);
  }

  openInvoicePopupModal() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    // dialogConfig.disableClose = true;
    dialogConfig.id = "modal-invoice-popup";
    dialogConfig.minHeight = "470px";
    if (this.innerWidth < 550) {
      dialogConfig.width = "95%";
    } else {
      dialogConfig.minWidth = "450px";
    }
    dialogConfig.maxHeight = "670px";
    dialogConfig.maxWidth = "550px";
    dialogConfig.data = {
      name: "invoicePopup",
      title: "BOLETO GERADO",
      description: `<p>Seu boleto está sendo baixado e estará disponível assim que o download for concluído.</p>
          <p>Confira a pasta padrão de seu dispositivo ou o local em que escolheu para salvar o arquivo.</p>`,
      // actionButtonText: "Entendi",
      isButtonEnabled: true,
      cancelButtonText: "Entendi",
      imageSrc: "../../../assets/icons/rounded-exclamation-icon.svg",
    };
    const modalDialog = this._matDialog.open(ModalComponent, dialogConfig);
  }

  componentInitialization() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.componentInitialization();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.componentInitialization();
  }
}
