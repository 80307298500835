import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Validations } from "src/utils/validations";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public loginError = true;
  public alreadyShownLogin = false;
  public innerWidth: any;
  private origin: string = "";

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    public _matDialog: MatDialog
  ) { }

  @Input()
  set originRoute(origin: string) {
    this.origin = origin;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.loginForm = this._formBuilder.group({
      username: ["", Validators.compose([Validators.required, Validations.ValidateContract]),],
      password: [
        "",
        Validators.compose([Validators.required, Validations.ValidateCpfCnpj]),
      ],
    });
    if (this.origin === "login") localStorage.removeItem("imobUser");
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this._route.snapshot.queryParams["returnUrl"] || "/dashboard";
  }

  openContractInfoModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-contract-info";
    dialogConfig.minHeight = "470px";
    if (this.innerWidth < 550) {
      dialogConfig.width = "95%";
      dialogConfig.height = "95%";
    } else {
      dialogConfig.minWidth = "450px";
    }
    dialogConfig.maxHeight = "738px";
    dialogConfig.maxWidth = "640px";
    dialogConfig.data = {
      name: "contractInfo",
      title: "Onde encontrar o número do contrato",
      description: `<div class="info-modal">
        <ol>
          <li>
            Tenha em mãos o seu boleto anterior.
          </li>
          <li>
            O campo "Nº Contrato" fica localizado no lado esquerdo do boleto, abaixo do campo "beneficiário".
          </li>
        </ol>
      </div>`,
      isButtonEnabled: false,
      imageFooterSrc: "../../assets/images/boleto.png",
    };
    this._matDialog.open(ModalComponent, dialogConfig);
  }

  public async onSubmit() {
    this.loading = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    this._authService.reOpenDashboard();

    if (this._matDialog) {
      this._matDialog.closeAll();
    }

    // Seta o CPF que pesquisou o contrato no localStorage
    localStorage.setItem("loggedDocument", this.f.password.value);
    this._authService.login(this.f.username.value, this.f.password.value).then(
      (data) => {
        this.loading = false;
        if (data.status === 204) {
          this.openInvalidCredentialsModal();
          this._authService.logout();
        } else {
          this._router.navigate([this.returnUrl]);
        }
      },
      (error) => {
        // Verifica se o erro é do tipo "Forbid - user xxxxxx is not the ownership"
        let regex = new RegExp("(Forbid - user )(.*)( is not the ownership)")

        if (error instanceof Array && error.some((e) => e.match(regex))) {
          this.loading = false;
          this.openInvalidCredentialsModal();
          console.error(error);
        } else {
          this.loading = false;
          this.openUnavailableLoginsModal();
          console.error(error);
        }
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  openUnavailableLoginsModal() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    // dialogConfig.disableClose = true;
    dialogConfig.id = "modal-unavailable-login";
    dialogConfig.minHeight = "470px";
    if (this.innerWidth < 550) {
      dialogConfig.width = "95%";
    } else {
      dialogConfig.minWidth = "450px";
    }
    dialogConfig.maxHeight = "670px";
    dialogConfig.maxWidth = "550px";
    dialogConfig.data = {
      name: "unavailableLogin",
      title: "Desculpe, nosso sistema está temporariamente fora do ar",
      description: `<p>Por favor entre em contato conosco nos canais:</p>
          <p>
            <a href="mailto:relacionamento.imobiliario@bancobari.com.br"
              >relacionamento.imobiliario@bancobari.com.br</a
            ><br />
            TEL: (41) 4007-2628 <br />
            <a target="_blank" href="https://wa.me/554130722353">WhatsApp: (41) 3072-2353 .</a>
          </p>
          <br />
          <p>Se seu boleto já venceu há mais de 30 dias utilize:</p>
          <p>
            <a href="mailto:cobrancaimobiliario@cebracopr.com.br"
              >cobrancaimobiliario@cebracopr.com.br</a
            ><br />
            TEL: (41) 3017-7855 ou 0800-600-7017 <br />
            <a target="_blank" href="https://wa.me/5541988353077">WhatsApp: (41) 98835-3077.</a>
          </p>
          `,
      // actionButtonText: "Entendi",
      isButtonEnabled: true,
      cancelButtonText: "Entendi",
      imageSrc: "../../../assets/icons/rounded-exclamation-icon.svg",
    };
    const modalDialog = this._matDialog.open(ModalComponent, dialogConfig);
  }

  openInvalidCredentialsModal() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    // dialogConfig.disableClose = true;
    dialogConfig.id = "modal-invalid-credentials";
    dialogConfig.minHeight = "470px";
    if (this.innerWidth < 550) {
      dialogConfig.width = "95%";
    } else {
      dialogConfig.minWidth = "450px";
    }
    dialogConfig.maxHeight = "670px";
    dialogConfig.maxWidth = "550px";
    dialogConfig.data = {
      name: "invalidCredentials",
      title: "Dados incorretos",
      description: `<p>Por favor verifique o número do contrato e do seu documento.</p>`,
      // actionButtonText: "Entendi",
      isButtonEnabled: true,
      cancelButtonText: "Entendi",
      imageSrc: "../../../assets/icons/rounded-exclamation-icon.svg",
    };
    const modalDialog = this._matDialog.open(ModalComponent, dialogConfig);
  }
}
