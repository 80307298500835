import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class DashboardService {
  private _user: any;
  private _invoice: any;
  private _cpfcnpj: any;

  constructor(private http: HttpClient, private _authService: AuthService) {
    this._user = this._authService.authServ.currentUserValue().data;
  }

  public getInstallments(user, date?): Promise<any> {
    const contract = user.id.split("-")[0]; // Pega o valor sem o digito
    this._cpfcnpj = localStorage
      .getItem("loggedDocument")
      .replace(/[.-\s\/]/g, "");
    return new Promise<any>((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.URL}/realestate/v1/contracts/${contract}/installments?uuid=${this._cpfcnpj}${date ? `&referenceDate=${date}` : ''}&environment=${user.environment}`
        )
        .pipe(
          tap((data: any) => {
            data.installments.sort((a, b) => (a.parcel > b.parcel ? -1 : 1));
          }),
          map((data: any) => {
            data.installments = data.installments.map((val) => {
              return {
                ...val,
                // Multiplicar e Dividir por 100 pra facilitar a subtração de decimais
                addition:
                  (val.totalPayable * 100 - val.totalCharges * 100) / 100,
                status: !!val.delayedDays ? "VENCIDA" : "A_VENCER",
              };
            });
            return data;
          })
        )
        .subscribe(
          (data) => {
            if (data["error"]) {
              reject(data["error"]);
            }
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public generateInvoice(user, parcels, date?): Observable<any> {
    const contract = user.id.split("-")[0];
    let headerJson = {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
    }; // Pega o valor sem o digito
    this._cpfcnpj = localStorage
      .getItem("loggedDocument")
      .replace(/[.-\s\/]/g, "");
    let url = `${environment.URL}/realestate/v1/contracts/${contract}/installments?uuid=${this._cpfcnpj}${date ? `&referenceDate=${date}` : ''}&environment=${user.environment}`;
    var body = parcels;

    return this.http.post(url, body, {
      responseType: "blob",
      headers: new HttpHeaders(headerJson)
    });
  }
}
