import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";

// parcel: "038/045"
// dueDate: "2019-11-12T00:00:00"
// delayedDays: 79
// totalPayable: 4161.63
// totalCharges: 3688.99
// addition: 472.64
// status: "VENCIDA"

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"]
})
export class TableComponent implements OnInit, OnChanges {
  @Input() data = [];
  @Input() hasDelayed30More = Boolean;
  @Output() select = new EventEmitter<any>(true);

  selectedElements: any[] = [];

  constructor() {
    this.selection.changed.asObservable().subscribe(() => {
      this.selectedElements = [...this.selection.selected];
    });
  }

  public displayedColumns: string[] = [
    "select",
    "parcel",
    "status",
    "dueDate",
    "totalCharges",
    "delayedDays",
    "addition",
    "totalPayable"
  ];

  public dataSource: any;
  public selection = new SelectionModel<any>(true, [], true);
  private _allSelecteds = [];

  public totalInvoiceValue: number = 0;
  public invoiceValues;
  public totalChargeValue: number = 0;
  public chargeValues;

  public getTotalInvoiceValue() {
    this.invoiceValues = this.selection.selected.map(
      selected => selected.totalPayable
    );

    this.totalInvoiceValue = this.invoiceValues
      .map(selected => (selected = selected * 100))
      .reduce((sum, current) => sum + current, 0);

    this.totalInvoiceValue = this.totalInvoiceValue / 100;
    return this.totalInvoiceValue;
  }

  public getTotalChargeValue() {
    if (this.selection.selected.length == 0) {
      this.totalChargeValue == 0;
    } else {
      this.chargeValues = this.selection.selected.map(
        selected => selected.totalCharges
      );

      this.totalChargeValue = this.chargeValues
        .map(selected => (selected = selected * 100))
        .reduce((sum, current) => sum + current, 0);

      this.totalChargeValue = this.totalChargeValue / 100;
    }
    return this.totalChargeValue;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    this.select.emit(this.selection.selected);
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.getTotalInvoiceValue();
    this.getTotalChargeValue();
    return numSelected === numRows;
  }

  public getSelectedParcels() {
    return this.selectedElements;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
    if (this.dataSource.data.length > 1 && !this.hasDelayed30More) {
      this.dataSource.data.filter(
        row => row.status == "VENCIDA" && this.selection.select(row)
      );
    }
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
  }

  public componentInitialization() {
    this.selection.clear()
    if (this.data){
      this.dataSource = new MatTableDataSource(this.data);
      if (this.dataSource.data.length <= 1 && !this.hasDelayed30More) {
        this.dataSource.data.forEach(row => this.selection.select(row));
      } else if (this.dataSource.data.length > 1 && !this.hasDelayed30More) {
        this.dataSource.data.filter(
          row => row.status == "VENCIDA" && this.selection.select(row)
        );
      }
    }
  }

  ngOnInit() {
    this.componentInitialization()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.componentInitialization()
  }
}
